

































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import {ListOptions} from '@/models/Booking';
import Base from "@/Base";

@Component
export default class WListOptions extends Base {
  @Prop() value!: ListOptions;
  @Prop({default: false, type: Boolean}) noEntryCount!: boolean;

  entryCountOptions = [
    { value: 10, text: this.$t('nEntriesPerPage', {number: 10}) as string },
    { value: 25, text: this.$t('nEntriesPerPage', {number: 25}) as string },
    { value: 50, text: this.$t('nEntriesPerPage', {number: 50}) as string },
    { value: 100, text: this.$t('nEntriesPerPage', {number: 100}) as string }
  ];

} 
