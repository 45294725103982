import { Contact, ContactsWrapper, GroupContact, ValidatedPhoneNumber } from "@/models/Contact";
import axios from "axios";
import { debounce } from "lodash";

const headers = {
  'accept': 'application/json',
  "Content-Type": "application/json"
}

interface ContactParams {
  page: number; size: number; order: string; direction: 'ASC' | 'DESC'; search?: string; groupName?: string;
}

/**
 * API calls for contacts
 * @module service/ContactsService
 */
export default {

  /**
   * Rest Functions contacts
   */
  getContacts(page: number, size: number, order: string, direction: 'ASC' | 'DESC', search?: string, groupName?: string): Promise<ContactsWrapper> {
    const params: ContactParams = {page, size, order, direction};
    if (search) params['search'] = search;
    if (groupName) params['groupName'] = groupName;
    return axios.get(`/contact`, {params, headers})
                .then(response => response.data);
  },

  getContact(id: string): Promise<Contact> {
    return axios.get(`/contact/${id}`)
                .then(response => response.data);
  },

  validatePhoneNumbers(numbers: string[]): Promise<Array<ValidatedPhoneNumber>> {
    return axios.post(`/validator/phonenumber`, numbers).then(response => response.data)
  },
  
  /**
   * Functions 
   */
  createValidationDebounce(validationFunction: () => void) {
    return debounce(validationFunction, 1000);
  },

  noLettersValidator(value: string): boolean {
    const valid = value && value !== '' ? /^[^a-zA-Z]+$/.test(value) : true;
    return valid;
  }

}