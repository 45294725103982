export default {
    sessionExpired: "Session abgelaufen",
    closeWindowAndLogin: 'Bitte schließen Sie dieses Fenster und melden sich erneut an.',
    callGroup: 'Gruppe anrufen',
    search: 'Suche',
    name: 'Name',
    phoneNumber: 'Telefonnummer',
    phoneNumberShort: 'Telefonr.',
    contacts: 'Kontakte',
    noGroup: 'Keine Gruppe',
    connectionEstablish: 'Verbindung wird hergestellt',
    conferenceEnded: 'Die Konferenz ist beendet',
    connectionProblems: 'Verbindungsprobleme',
    reloadPage: 'Seite neu laden',
    connectParticipant: 'Teilnehmer zuschalten',
    selectedParticipants: 'ausgewählte Teilnehmer',
    callAsModerator: 'Als Moderator anrufen',
    callAsMod: 'Als Mod. anrufen',
    callAllParticipants: 'Als Teilnehmer anrufen',
    callAsPart: 'Als Tln. anrufen',
    wantConnectionAsModeratorOrParticipant: 'Wünschen Sie eine Zuschaltung als Moderator oder Teilnehmer?',
    moderatorAdded: 'Moderator hinzugefügt',
    participantAdded: 'Teilnehmer hinzugefügt',
    participantExists: 'Teilnehmer existiert bereits',
    requestToSpeakDeleted: 'Alle Wortmeldungen wurden gelöscht',
    serverErrorOccurred: 'Ein Server Fehler ist aufgetreten',
    mute: 'stumm schalten',
    call: 'anrufen',
    disconnect: 'trennen',
    park: 'parken',
    switchLoud: 'laut schalten',
    free: 'frei',
    blocked: 'geblockt',
    dialing: 'Anwahl',
    occupied: 'besetzt',
    greeting: 'Begrüßung',
    speaker: 'Sprecher',
    listener: 'Zuhörer',
    parked: 'geparkt',
    later: 'später',
    notReached: 'n. erreicht',
    unknown: 'unbekannt',
    undefined_: 'undefiniert',
    nEntriesPerPage: '{number} Einträge/Seite',
    tableview: 'Tabllenansicht',
    mapview: 'Kartenansicht',
    selectedParticipantsCap: 'Ausgewählte Teilnehmer',
    selectAction: 'Aktion wählen',
    muteIfConnected: 'stumm schalten, sofern verbunden',
    switchLoudIfConnected: 'laut schalten, sofern verbunden',
    parkIfConnected: 'parken, sofern verbunden',
    callIfDisconnected: 'anrufen, sofern getrennt',
    invertSelection: 'Auswahl umkehren',
    deselect: 'Auswahl aufheben',
    connection2: 'Zuschaltung',
    callNumber: 'Rufnummer',
    fieldRequired: 'Pflichtfeld muss ausgefüllt werden',
    insertValidNumber: 'Bitte geben Sie eine valide Handynummer',
    playAudio: 'Audio einspielen',
    playback: 'Playback',
    selectAudiofile: 'Audiodatei auswählen',
    audio: 'Audio',
    moderatorShort: 'Mod.',
    participantShort: 'Tln.',
    moderatorPin: 'PIN-Moderatoren',
    participantPin: 'PIN-Teilnehmer',
    dialInNumbers: 'Einwahlrufnummern',
    conference: 'Konferenz',
    ifConnected: ', sofern verbunden',
    ifDisconnected: ', sofern getrennt',
    delete: 'löschen',
    addressbook: 'Adressbuch',
    finish: 'Beenden',
    recording: 'Aufzeichnung',
    recordingShort: 'Aufzeich.',
    recordingActive: 'Mitschnitt läuft',
    recordingNotActive: 'Mitschnitt nicht aktiviert',
    conferenceShort: 'Konf.',
    roomOpen: 'Konferenzraum offen',
    roomLocked: 'Konferenzraum gesperrt',
    waitingRoom: 'Warteraum',
    waitingRoomShort: 'Wart.',
    waitingRoomActive: 'Warteraum aktiviert',
    waitingRoomOff: 'Warteraum aus',
    participantSearch: 'Teilnehmer Suche',
    startRecordingModal: {
        title: 'Aufzeichnung starten',
        msg: 'Möchten Sie Aufzeichnung starten? Diese kann nicht wieder ausgeschaltet werden',
    },
    endConferenceModal: {
        title: 'Konferenz beenden',
        msg: 'Möchten Sie die Konferenz beenden?',
    },
    cancel: 'Abbrechen',
    wantToEndConference: 'Möchten Sie die Konferenz beenden?',
    all: 'Alle',
    speakers: 'Sprecher',
    listeners: 'Zuhörer',
    requestToSpeaks: 'Meldungen',
    requestsToSpeakShorter: 'Meldungen',
    speakerShort: 'Spr.',
    listenerShort: 'Zuh.',
    requestToSpeakShort: 'Meld.',
    start: 'Start',
    status: 'Status',
    participantInConference: 'Teilnehmer bereits im Konferenz',
    pageOf: 'Seite {current} von {total}',
    shownElements: 'Angezeigte {title}',
    elements: 'Elemente',
    elementsOf: '{current} von {total}',
    asModerator: 'Als Moderator',
    asParticipant: 'Als Teilnehmer',
    extras: 'Extras',
    allParticipants: 'Alle Teilnehmer',
    allRequestsToSpeak: 'Alle Wortmeldungen',
    selectGroup: 'Gruppe auswählen'
}