import { Group } from "@/models/Group";
import axios from "axios";

const headers = {
  'accept': 'application/json',
  'content-type': 'application/json'
}

/**
 * API calls for contacts
 * @module service/GroupsService
 */
export default {
  
  getGroups(search?: string): Promise<Group[]> {
    return axios.get(`/group`, {params: {search}})
                .then(response => response.data);
  },

  getGroup(id: string): Promise<Group> {
    return axios.get(`/group/${id}`)
                .then(response => response.data);
  },

  updateGroup(id: string, group: Group): Promise<Group> {
    return axios.put(`/group/${id}`, group)
                .then(response => response.data);
  },

  addGroup(group: Group): Promise<Group> {
    return axios.post(`/group`, group)
                .then(response => response.data);
  },

  deleteGroup(id: string, withContacts?: boolean): Promise<void> {
    const params: {[key:string]: string} = {}
    if (withContacts) {
      params['withContacts'] = 'true';
    }
    // Delete with axios works only with payload
    return axios.delete(`/group/${id}`, {data: {text: 'lorem'}, params})
                .then();
  },


}