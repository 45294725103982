


























































































































































import {Component} from "vue-property-decorator";
import CHeader from "./components/CHeader.vue";
import CBodyVirtual from "./components/CBodyVirtual.vue";
import CFooter from "./components/CFooter.vue";
import {
  Account,
  AuthenticationStorage,
  TokenPayload,
  Tokens,
} from "@/models/Account";
import ConferenceService from "@/service/ConferenceService";
import {
  Conference,
  ConferenceParticipant,
  ConferenceParticipantAudioType,
  ConferenceParticipantRole,
  ConferenceRow,
  DialOutParticipant,
} from "./models/Conference";
import {Booking} from "./models/api";
import Base from "./Base";
import CAdressbook from "@/components/CAdressbook.vue";
import {Contact, SelectOption, TableContact} from "./models/Contact";
import ContactsService from "./service/ContactsService";
import axios from "axios";
import jwt_decode from "jwt-decode";

export interface TokenAccount {
  token: string;
  account: Account;
}

@Component({
  components: {CHeader, CFooter, CAdressbook, CBodyVirtual},
})
export default class App extends Base {
  sourceWindow!: Window;
  permittedOrigins!: string[];
  origin!: string;
  bookingId = "";
  conference: Conference | null = null;
  booking: Booking | null = null;
  rows: ConferenceRow[] = [];

  // contains hidden and visible rows, key is the row.id
  allRowsMap: Map<string, ConferenceRow> = new Map(); 

  // contains only visible rows, key is phone or dialInPhone~id or dialInPhone~disconnected
  // key separated to 3 categories to allow for multiple active DialInNumbers, and only 1 DialOut Number
  visibleRowsMap: Map<string, ConferenceRow> = new Map();

  // give every phone number a unique order index, key is phone, value is the order index
  rowsOrderMap: Map<string, number> = new Map();

  loading = false;
  participantLoading = false;

  participantInterval!: number;
  conferenceInterval!: number;

  // active adressbook contacts
  phones: string[] = [];

  selectedParticipants: ConferenceRow[] = [];
  selectedContacts: TableContact[] = [];

  resetCounts = {
    speaker: 0,
    listeners: 0,
    parked: 0,
    rts: 0,
    total: 0,
  };

  counts = {
    speaker: 0,
    listeners: 0,
    parked: 0,
    rts: 0,
    total: 0,
  };

  lastStatusCode = 0;
  adressbookGroup = '';

  get authenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }

  mounted(): void {
    this.loading = true;

    if (process.env.VUE_APP_PERMITTED_W3_CLIENT_URLS === undefined) {
      const standardURL = "http://localhost:8080";
      console.log("No environment variable 'VUE_APP_PERMITTED_W3_CLIENT_URLS' found. Use " + standardURL);
      this.permittedOrigins = [];
    } else {
      this.permittedOrigins = process.env.VUE_APP_PERMITTED_W3_CLIENT_URLS.split(",");
    }

    this.init();

    this.participantInterval = setInterval(() => {
      if (this.conference && !this.participantLoading) {
        this.updateParticipants(this.conference);
      }
    }, 2000);

    this.conferenceInterval = setInterval(() => {
      if (this.$store.getters.username) {
        this.getConference();
      }
    }, 5000);
  }

  destroyed(): void {
    if (this.participantInterval) clearInterval(this.participantInterval);
    if (this.conferenceInterval) clearInterval(this.conferenceInterval);
  }

  init(): void {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    if (id) {
      this.bookingId = id;
    }
    if (params.get("openFromClient") == null) {
      // Page was reloaded, get authentication from localstorage
      this.$store.dispatch("loadAuthentication");
      if (this.authenticated) {
        this.getConference();
      } else {
        this.loading = false;
      }
    } else {
      // Page was opened from client
      // Remove the parameter "openFromClient", this is the flag to detect reloads
      console.log("remove parameter");
      const url = new URL(window.location.toString());
      url.searchParams.delete("openFromClient");
      window.history.pushState({}, "Conferencing", url.toString());
    }

    const language = params.get("language")
    if(language!= null) {
      console.log("Set language to " + language)
      this.$i18n.locale = language;
      localStorage.setItem('language', language);
    }

    setInterval(() => {
      console.log("Check token");
      if (this.$store.getters.username) {
        // Refresh the tokens 5 minutes before token expires
        const refreshLimitExpiration =
            +this.$store.getters.expiration - 1000 * 60 * 5;
        console.log("Refresh time:", new Date(refreshLimitExpiration));
        if (Date.now() > refreshLimitExpiration) {
          console.log("refresh");
          axios
              .post("/refresh", {
                refreshToken: this.$store.getters.refreshToken,
              })
              .then((res) => {
                const tokens: Tokens = res.data;
                console.log("login data received: ", tokens);
                const payload: TokenPayload = jwt_decode<TokenPayload>(
                    tokens.accessToken
                );
                this.$store.commit("setAuthentication", {
                  accessToken: tokens.accessToken,
                  refreshToken: tokens.refreshToken,
                  username: payload.sub,
                  expiration: payload.exp * 1000,
                });
              })
              .catch((err) => {
                this.$store.commit("clearAuthentication");
                this.conference = null;
                console.error("Error getting new token", err);
              });
        }
      }
    }, 10000);

    window.addEventListener("message", this.messageHandler);
  }

  messageHandler(event: MessageEvent): void {
    if (this.permittedOrigins.find(item => event.origin.endsWith(item)) === undefined) return;

    this.origin = event.origin;
    this.sourceWindow = event.source as Window;
    if (this.sourceWindow && event.data === "connecting") {
      this.sourceWindow.postMessage("connected", this.origin);
    }
    if (event.data.includes("authentication=")) {
      const authenticationStr = event.data.split("=")[1];
      if (!this.$store.getters.receivedAuthenticationFromClient) {
        const authentication: AuthenticationStorage =
            JSON.parse(authenticationStr);
        console.log("First authentication received", authentication);
        this.$store.commit("setAuthentication", authentication);
        this.$store.commit("receivedAuthenticationFromClient", true);
        this.getConference();
      } else {
        console.log("Another authentication received, ignore");
      }
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  getConference(): void {
    ConferenceService.getConference(this.bookingId)
        .then((data) => {
          if (this.conference) {
            this.conference = data;
            return Promise.resolve();
          }
          this.conference = data;
          this.booking = data.booking;
          return this.getAllParticipants(data);
        })
        .catch((err) =>
            console.log(
                err.message,
                "No conference found. Requested conference may have ended."
            )
        )
        .then(() => {
          this.loading = false;
        });
  }

  endConference(): void {
    if (this.sourceWindow && this.origin) {
      this.sourceWindow.postMessage("disconnected", this.origin);
    }
    window.close();
  }

  addGroupFromAdressbook(group: string): void {
    this.adressbookGroup = group;
    this.$bvModal.show('modal-add-group-mobile');
  }

  addFromAddressbook(
      role: ConferenceParticipantRole,
      modalOk: () => void
  ): void {

    const participants: DialOutParticipant[] = [];

    this.iterate(this.selectedContacts, item => {
      if (this.participantDoesntExist(item, role)) {
        participants.push({
          phone: item.telephone,
          label: item.name,
          company: item.company,
          matchAddressbook: true,
          role,
        });
      }
    });

    if (this.conference) {
      if (participants.length > 0) {
        ConferenceService.addParticipants(this.conference.id, participants)
            .then(() => {
              if (role === "MODERATOR")
                this.toast(this.t('moderatorAdded'), "success");
              else this.toast(this.t('participantAdded'), "success");
              modalOk();
            })
            .catch((err) => this.toast(err.message, "danger"));
      }
    }
    modalOk();
  }

  // Add adressbook group contacts
  addGroupContacts(event: { group: any, role: 'MODERATOR' | 'PARTICIPANT' }): void {
    if (this.conference && event.group.count) {
      ContactsService.getContacts(
          0,
          event.group.count,
          "dateCreated",
          "ASC",
          "",
          event.group.title
      )
          .then((wrapper) => {
            if (!wrapper.empty) {
              const participants: DialOutParticipant[] = [];

              this.iterate(wrapper.content, (c) => {
                if (this.participantDoesntExist(c)) {
                  participants.push({
                    phone: c.telephone,
                    label: c.name,
                    matchAddressbook: true,
                    role: event.role
                  });
                }
              });

              if (this.conference) {
                if (participants.length > 0) {
                  // participants that don't exist in conference
                  ConferenceService.addParticipants(
                      this.conference.id,
                      participants
                  )
                      .then(() => this.toast(this.t('participantAdded'), "success"))
                      .catch((err) => this.toast(err.message, "danger"));
                }
              }
            }
            this.$bvModal.hide('modal-adressbook');
            this.adressbookGroup = '';
          })
          .catch((err) => this.toast(err.message, "danger"));
    }
  }

  // add registration form group contacts
  addFormContacts(event: { group: any, role: 'MODERATOR' | 'PARTICIPANT' }): void {
    if (this.conference && this.booking) {
      // if booking has a registration form assigned
      // call participants of that group
      if (this.booking.regDate) {
        ConferenceService.addParticipantsFromForm(
            this.conference.id,
            this.booking.regDate.regId.toString(),
            this.booking.regDate.dateId.toString()
        ).then();
      } else if(event.group.reg) {
        // otherwise call participants of the selected group
        ConferenceService.addParticipantsFromForm(
            this.conference.id,
            event.group.reg.id,
            event.group.value
        ).then();

      }
    }
  }

  addParticipant(participant: DialOutParticipant): void {
    if (this.conference) {
      let row!: ConferenceRow;
      for (let i = 0; i < this.rows.length; i++) {
        const iterRow = this.rows[i];
        if (iterRow.telephone === participant.phone.split(" ").join("")) {
          row = iterRow;
          break;
        }
      }
      const rowExistsAndDisconnected = (row && this.isDisconnected(row.status));
      if (!row || rowExistsAndDisconnected) {
        ConferenceService.addParticipants(this.conference.id, [
          participant,
        ]).then(() => {
          this.toast(this.t('participantAdded'), "success");
        });
      } else {
        this.toast(this.t('participantExists') + '.', "danger");
      }
    }
  }

  // filter function, checks if participants exist (return false) or doesn't (return true)
  private participantDoesntExist(
      c: Contact,
      role?: ConferenceParticipantRole
  ) {
    // iterate through rows with for loop for better performance
    for (let i = 0; i < this.rows.length; i++) {
      const existingRow = this.rows[i];
      // if participant exists
      // and role is the same
      if (
          existingRow.telephone === c.telephone.split(" ").join("") &&
          ((role && existingRow.role === role) || !role)
      ) {
        if (!this.isDisconnected(existingRow.status)) {
          return false;
        }
      }
    }
    // if participant doesn't exist or is disconnected
    return true;
  }

  deleteAllRts(): void {
    if (this.conference) {
      const rtsRows: string[] = [];
      this.iterate(this.rows, (item) => {
        if (item.rts && item.rtsIndex > 0) {
          rtsRows.push(item.id);
        }
      });
      ConferenceService.updateRTS(this.conference.id, rtsRows, "OFF")
          .then(() => this.toast(this.t('requestToSpeakDeleted'), "success"))
          .catch((err) => console.log(err.message));
    }
  }

  // all participants changed from header 
  changeStatusAll(status: ConferenceParticipantAudioType): void {
    const rows: ConferenceRow[] = [];
    this.iterate(this.rows, (item) =>
        !item.inactive ? rows.push(item) : ""
    );
    this.updateStatus(status, rows, "all");
  }

  // selected participants changed from footer
  changeStatusSelected(status: ConferenceParticipantAudioType): void {
    this.updateStatus(status, this.selectedParticipants, "selected");
  }

  // one participant changed from table row
  changeStatusOne(res: {
    status: ConferenceParticipantAudioType;
    participant: ConferenceRow;
  }): void {
    this.updateStatus(res.status, [res.participant], "selected");
  }

  private updateStatus(
      status: ConferenceParticipantAudioType,
      rows: ConferenceRow[],
      mode: "all" | "selected",
      role?: ConferenceParticipantRole
  ): void {
    if (this.conference) {
      let statusChangedItems: ConferenceRow[] = [];
      let toBeCalledRows: ConferenceRow[] = [];
      let roleChangedRows: ConferenceRow[] = [];

      // iterate over the participant rows
      this.iterate(rows, (confRow: ConferenceRow) => {
        // remove the id or "disconnected" from after dialin numbers to compare them
        const newItem = {...confRow, telephone: confRow.telephone.split('~')[0]}

        // if item is disconnected and new status is 'dialing'
        if (this.isDisconnected(newItem.status) && status === 'DIALING') {
          toBeCalledRows.push(newItem);
        } else if (
          // if disconnected and role changed
          this.isDisconnected(newItem.status) &&
          role &&
          newItem.role !== role
        ) {
          roleChangedRows.push(newItem);
        } else if (

          // if all participants were changed, and is moderator, don't change
          (newItem.role === "MODERATOR" && mode === "all") ||
          
          // if the old and new status are the same, don't change
          newItem.status === status ||
          
          // if disconnected and newStatus is not dialing, don't change
          (this.isDisconnected(newItem.status) && status !== "DIALING") ||
          
          // if disconnected and newStatus is disconnected, don't change
          (this.isDisconnected(newItem.status) && status === "DISCONNECTED") ||
          
          // if active and newStatus is dialing, don't change
          (!this.isDisconnected(newItem.status) && status === "DIALING")

        ) {
          // do nothing
        } else {
          statusChangedItems.push(newItem);
        }
      });

      if (statusChangedItems.length > 0) {
        const newStatusItemIds: string[] = [];
        this.iterate(statusChangedItems, (item) => newStatusItemIds.push(item.id));
        ConferenceService.updateStatus(
            this.conference.id,
            newStatusItemIds,
            status
        )
            .then()
            .catch((err) => console.log(err.message));
      }

      if (toBeCalledRows.length > 0) {
        const newItems: DialOutParticipant[] = [];
        this.iterate(toBeCalledRows, (item) => {
          newItems.push({
            phone: item.telephone,
            label: item.name,
            company: item.company,
            matchAddressbook: true,
            role: item.role,
          });
        });
        ConferenceService.addParticipants(this.conference.id, newItems).then();
      }
      if (roleChangedRows.length > 0 && role) {
        const newItems: DialOutParticipant[] = [];

        this.iterate(roleChangedRows, (item) => {
          newItems.push({
            phone: item.telephone,
            label: item.name,
            company: item.company,
            matchAddressbook: true,
            role: item.role,
          });
        });
        ConferenceService.addParticipants(this.conference.id, newItems).then();
      }
    }
  }

  private isDisconnected(status: ConferenceParticipantAudioType): boolean {
    return ConferenceService.isDisconnected(status);
  }

  // init or reload
  private getAllParticipants(conference: Conference) {
    return ConferenceService.getAllParticipants(conference.id)
        .then((participants) => {
          
          if (participants) {

            // initialize empty rows
            this.rows = [];

            // iterate with a for loop
            this.iterate(participants, (participant) => {

              // create row, add index to rowsOrderMap, and add the row to allRowsMap
              const row = this.participantToRow(participant);
              this.allRowsMap.set(row.id, row);

              // check the row is visible
              const oldRow = this.visibleRowsMap.get(row.telephone);

              // if it's visible and it's dialOut check if it's newer than the visible row 
              if (oldRow && participant.dialOut) {

                // if it's newer, replace in visibleRowsMap and give it the index
                if (oldRow.startMilli < row.startMilli) {
                  this.visibleRowsMap.set(row.telephone, row);
                  row.number = oldRow.number;
                }

              } else {
                // if the row is not visible, make it visible
                // disconnected dialIn numbers will be automatically replaced here
                this.visibleRowsMap.set(row.telephone, row);
              }

            });

            // mount the visible rows in the view
            this.rows = Array.from(this.visibleRowsMap.values());
            this.phones = this.rows.map((row) => row.telephone);
            this.count();
          }
        })
        .catch((err) => this.toast(err.message, "danger"));
  }

  // update
  private updateParticipants(conference: Conference) {
    console.log("updating participants");
    this.participantLoading = true;
    return ConferenceService.getAllParticipants(conference.id)
        .then((participants) => {
          this.phones = [];
          if (participants) {
            this.iterate(participants, (participant: ConferenceParticipant) => {

              const entry = this.allRowsMap.get(participant.id);

              // DIAL OUT
              if(participant.dialOut) {

                const shownRow = this.visibleRowsMap.get(participant.calleePhone);

                // if entry exists and is visible
                if (entry && shownRow && entry.telephone === shownRow.telephone) {
                  this.updateRow(shownRow, participant)
                } else {
                  // if row doesn't exist but the phone number exists, update phone map and add row
                  if (!entry && shownRow) {
                    if (shownRow.startMilli < participant.beginDate) {
                      const newRow = this.participantToRow(participant);
                      newRow.number = shownRow.number;
                      this.visibleRowsMap.set(shownRow.telephone, newRow);
                      this.allRowsMap.set(newRow.id, newRow)
                      this.updateTableRow(newRow, 'all')
                    }
                  }

                  // if row and phone number don't exist, add row to both maps
                  if (!entry && !shownRow) {
                    const newRow = this.participantToRow(participant);
                    this.visibleRowsMap.set(newRow.telephone, newRow);
                    this.allRowsMap.set(newRow.id, newRow)
                    this.rows.push(newRow);
                  }

                }
              } else {
                
                // DIAL IN
                const isActive = !this.isDisconnected(participant.audioState);
                const key = `${participant.callerPhone}~${participant.id}`;
                const shownRow = this.visibleRowsMap.get(key);
                const disconnectedKey = `${participant.callerPhone}~disconnected`;
                const disconnectedRow = this.visibleRowsMap.get(disconnectedKey);

                // if incoming is active
                if(isActive) {

                  // if entry exists, is active and the phone is the same
                  if(shownRow && entry && shownRow.telephone === entry.telephone) {
                    this.updateRow(shownRow, participant)
                  }

                  if(!entry && disconnectedRow) {
                    // delete disconnected row
                    this.visibleRowsMap.delete(disconnectedKey);

                    // find index of to delete row
                    let idx;
                    for (let i=0; i < this.rows.length; i++) {
                      if(this.rows[i].telephone === disconnectedRow.telephone) {
                        idx = i;
                        break;
                      }
                    }

                    // create new row
                    const newRow = this.participantToRow(participant);
                    this.visibleRowsMap.set(newRow.telephone, newRow);
                    this.allRowsMap.set(newRow.id, newRow);
                    // replace or add
                    if(idx || idx === 0) {
                      this.rows.splice(idx, 1, newRow);
                    } else {
                      this.rows.push(newRow);
                    }
                  }

                  if(!entry && !shownRow && !disconnectedRow) {
                    const newRow = this.participantToRow(participant);
                    this.visibleRowsMap.set(newRow.telephone, newRow);
                    this.allRowsMap.set(newRow.id, newRow)
                    this.rows.push(newRow);
                  }

                } else {

                  // if incoming is inactive
                  // if entry exists and was active, it needs to be removed
                  if(shownRow && entry && shownRow.telephone === entry.telephone) {
                    const newRow = this.participantToRow(participant);

                    // remove from map
                    this.visibleRowsMap.delete(key);

                    // find index of to delete row
                    let idx;
                    for (let i=0; i < this.rows.length; i++) {
                      if(this.rows[i].telephone === shownRow.telephone) {
                        idx = i;
                        break;
                      }
                    }

                    // replace row
                    let rowReplaced = false;

                    // if there was no disconnected row, add one
                    if (!disconnectedRow) {
                      // check if there's another connected one
                      const shownKeys = Array.from(this.visibleRowsMap.keys()); 
                      const otherShownRow = shownKeys.find(k => 
                        k.includes(participant.callerPhone) && this.visibleRowsMap.get(k)?.dialin
                      )
                      if(otherShownRow) {
                        // do nothing
                      } else {
                        this.visibleRowsMap.set(disconnectedKey, newRow);
                        this.allRowsMap.set(newRow.id, newRow)
                        if (idx || idx === 0) {
                          this.rows.splice(idx, 1, newRow);
                          rowReplaced = true;
                        } else {
                          this.rows.push(newRow);
                        }
                      }
                    }

                    // if there's another disconnected row, delete active row
                    if(!rowReplaced && (idx || idx === 0)) {
                      this.rows.splice(idx, 1)
                    }

                    // if there was a disconnected row
                    if(disconnectedRow) {
                      // if new row starts later, replace
                      if (disconnectedRow.startMilli < newRow.startMilli) {
                        this.visibleRowsMap.set(disconnectedKey, newRow);
                        this.allRowsMap.set(newRow.id, newRow)
                        this.updateTableRow(newRow, 'all')
                      }
                    }
                  }

                  // if not active entry didn't exist but disconnected row exists
                  if (!entry && disconnectedRow) {
                    if (disconnectedRow.startMilli < participant.beginDate) {
                      const newRow = this.participantToRow(participant);
                      this.visibleRowsMap.set(disconnectedKey, newRow);
                      this.allRowsMap.set(newRow.id, newRow)
                      this.updateTableRow(newRow, 'all')
                    }
                  }

                  // if not active entry didn't exist
                  if (!entry && !disconnectedRow) {
                    const newRow = this.participantToRow(participant);
                    this.visibleRowsMap.set(newRow.telephone, newRow);
                    this.allRowsMap.set(newRow.id, newRow)
                    this.rows.push(newRow);
                  }

                }
              }

              if (!this.isDisconnected(participant.audioState)) {
                this.phones.push(participant.dialOut ? participant.calleePhone : participant.callerPhone);
              }
            });

            this.count();

            const body = this.$refs["body-virtual"] as CBodyVirtual;
            if (body.tabIndex !== 0) {
              body.setFilter(body.tabIndex);
              const sortBy = body.tabIndex === 4 ? 
                'rtsIndex' : 
                (body.sortBy === 'rtsIndex' ? 'id' : body.sortBy)
              body.sort(sortBy, true);
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 500 || err.response.status === 404) {
            clearInterval(this.participantInterval);
            clearInterval(this.conferenceInterval);
            this.conference = null;
            this.lastStatusCode = err.response.status;
            if (err.response.status === 404) {
              this.toast(this.t('conferenceEnded'), "danger");
            }
            if (err.response.status === 500) {
              this.toast(this.t('serverErrorOccurred'), "danger");
            }
          } else {
            console.log(err);
          }
        })
        .finally(() => this.participantLoading = false);
  }

  private updateRow(
      shownRow: ConferenceRow,
      participant: ConferenceParticipant
  ): void {
    if(shownRow.dialin === !participant.dialOut || !shownRow.dialin === participant.dialOut) {
      // if status not similar
      if (shownRow.name !== participant.label) {
        // set new status and update table
        shownRow.name = participant.label;
        this.updateTableRow(shownRow, "name");
      }
      // if status not similar
      if (shownRow.status !== participant.audioState) {
        // set new status and update table
        shownRow.status = participant.audioState;
        this.updateTableRow(shownRow, "status");
      }
      // if rts not similar
      if (shownRow.rts !== participant.rtsState) {
        // set new rts and update table
        shownRow.rts = participant.rtsState;
        this.updateTableRow(shownRow, "rts");
      }
      if (shownRow.rtsIndex !== participant.rtsIndex) {
        // set new rts and update table
        shownRow.rtsIndex = participant.rtsIndex;
        this.updateTableRow(shownRow, "rtsIndex");
      }
    }
  }

  private updateTableRow(
      row: ConferenceRow,
      attr: "status" | "rts" | "rtsIndex" | "name" | "all"
  ): void {
    for (let i = 0; i < this.rows.length; i++) {
      if (this.rows[i].telephone === row.telephone) {
        if (attr === "status" || attr === 'all') this.rows[i].status = row.status;
        if (attr === "rts" || attr === 'all') this.rows[i].rts = row.rts;
        if (attr === "rtsIndex" || attr === 'all') this.rows[i].rtsIndex = row.rtsIndex;
        if (attr === "name" || attr === 'all') this.rows[i].name = row.name;
        if (attr === "all") {
          this.rows[i].id = row.id;
          this.rows[i].startMilli = row.startMilli;
          this.rows[i].dialin = row.dialin;
          this.rows[i].role = row.role;
        }
        // TODO break; to stop loop
      }
    }
  }

  private count() {
    this.counts = {...this.resetCounts};
    this.iterate(this.rows, (row) => {
      if (!row.inactive) {
        this.counts.total++;
        switch (row.status) {
          case "CONFERENCING":
            this.counts.speaker++;
            break;
          case "MUTED":
            this.counts.listeners++;
            break;
          case "PARKED":
            this.counts.parked++;
            break;
        }
        if (row.rts && row.rts !== 'OFF') this.counts.rts++;
      }
    });
  }

  private participantToRow(
      participant: ConferenceParticipant
  ): ConferenceRow {
    const date = new Date(participant.beginDate);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    
    const phone = participant.dialOut ? participant.calleePhone : participant.callerPhone;

    let phoneIndex = this.rowsOrderMap.get(phone);

    if(!phoneIndex) {
      phoneIndex = this.rowsOrderMap.size + 1;
      this.rowsOrderMap.set(phone, phoneIndex)
    }

    return {
      id: participant.id,
      number: phoneIndex,
      name: participant.label,
      telephone: this.setTelephone(participant),
      start: `${hours}:${minutes}`,
      startMilli: participant.beginDate,
      status: participant.audioState,
      rts: participant.rtsState,
      rtsIndex: participant.rtsIndex,
      selected: !!this.selectedParticipants.find(
          (p) => p.id === participant.id
      ),
      role: participant.role,
      dialin: !participant.dialOut,
    };
  }

  private setTelephone(participant: ConferenceParticipant): string {
    if (participant.dialOut) {
      return participant.calleePhone;
    } 
    if (this.isDisconnected(participant.audioState)) {
      return `${participant.callerPhone}~disconnected`
    }
    return `${participant.callerPhone}~${participant.id}`;
  }

  // Iterate over list with for loop for better performance, important for big lists
  private iterate(list: any[], callback: (item: any) => void) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      callback(item);
    }
  }
}
