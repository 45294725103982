


















import { ConferenceParticipantAudioType, ConferenceRow } from '@/models/Conference';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Base from "@/Base";

@Component
export default class CBodyStatus extends Base {
  @Prop() participant!: ConferenceRow;

  changeStatus(newStatus: string): void {
    this.$emit('statusChanged', newStatus);
  }

  status(status: ConferenceParticipantAudioType): string {
    switch(status) {
      case 'DISCONNECTED': return this.t('free');
      case 'DISABLED': return this.t('blocked');
      case 'DIALING': return this.t('dialing');
      case 'BUSY': return this.t('occupied');
      case 'SALUTATION': return this.t('greeting');
      case 'CONFERENCING': return this.t('speaker');
      case 'MUTED': return this.t('listener');
      case 'PARKED': return this.t('parked');
      case 'HANGUP': return this.t('free');
      case 'CONNECTION_LOST': return this.t('free');
      case 'LATER': return this.t('later');
      case 'FAILED': return this.t('notReached');
      case 'UNKNOWN': return this.t('unknown');
      default: return this.t('undefined_');
    }
  }

  get callable(): boolean {
    const state = this.participant.status;
    return state === 'DISCONNECTED' || state === 'FAILED' || state === 'BUSY' || state === 'HANGUP' || state === 'CONNECTION_LOST' || state === 'UNKNOWN';
  }

  get mutable(): boolean {
    const state = this.participant.status;
    return state === 'CONFERENCING' || state === 'PARKED';
  }
  
  get speakable(): boolean  {
    const state = this.participant.status;
    return state === 'MUTED' || state === 'PARKED';
  }
  get disconnectable(): boolean {
    const state = this.participant.status;
    return state === 'SALUTATION' || state === 'CONFERENCING' || state === 'MUTED' || state === 'PARKED';
  }
  get parkable(): boolean {
    const state = this.participant.status;
    return state === 'CONFERENCING' || state === 'MUTED';
  }

  // virtual scroller bugfix
  ddOpened(event: any): void {
    (this.$el.closest('.vue-recycle-scroller__item-view') as any).style.zIndex = '1';
  }

  ddClosed(event: any): void {
    (this.$el.closest('.vue-recycle-scroller__item-view') as any).style.zIndex = '0';
  }

}
