import axios from "axios";
import {Booking, NationalNumber} from "@/models/api";

/**
 * API calls for booking
 * @module service/BookingService
 */
export default {

  /**
   * Rest Functions bookings
   */
  getBookings(): Promise<Array<Booking>> {
    console.log("getBookings")
    return axios.get('/booking').then(response => response.data);
  },
  getBooking(id: string): Promise<Booking> {
    console.log("getBooking")
    return axios.get('/booking/' + id).then(response => response.data);
  },

  createBooking(booking: Booking): Promise<string> {
    return axios.post('/booking', booking).then(response => response.data);
  },

  deleteBooking(id: string): Promise<Booking> {
    return axios.delete(`/booking/${id}`).then(response => response.data);
  },

  updateBooking(booking: Booking): Promise<Booking> {
    return axios.put(`/booking/${booking.id}`, booking).then(response => response.data);
  },

  getNationalNumbers(): Promise<NationalNumber[]> {
    return axios.get(`/phonenumber`).then(res => res.data);
  },
}