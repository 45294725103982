import Vue from 'vue';
// import Component from 'vue-class-component';

export enum Events {
  Search = 'search',
  SelectedRows = 'selectedRows',
  SwitchSelection = 'switchSelection',
  DeselectAll = 'deselectAll'
}

export const EventBus = new Vue();
