












































































































































































































































































import Base from "@/Base";
import { EventBus, Events } from "@/EventBus";
import { Booking } from "@/models/api";
import {
  Conference,
  ConferenceParticipantAudioType,
  DialOutParticipant,
} from "@/models/Conference";
import { Playback } from "@/models/Playback";
import ConferenceService from "@/service/ConferenceService";
import ContactsService from "@/service/ContactsService";
import GroupsService from "@/service/GroupsService";
import PlaybackService from "@/service/PlaybackService";
import { Component, Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import RegistrationService from "@/service/RegistrationService";
import { Registration, RegistrationDate } from "@/models/Registration";

@Component
export default class CFooter extends Base {
  @Prop() booking!: Booking;
  @Prop() conference!: Conference;

  selected = null;

  @Prop() selectedRows!: string[];

  group = "";
  bulkGroup = "";
  groups: any[] = [
    {
      title: "Gruppe auswählen",
      text: this.t('selectGroup'),
      value: "",
      count: 0,
    },
  ];

  newParticipantForm = {
    name: "",
    phone: "",
  };
  resetParticipantForm = {
    name: "",
    phone: "",
  };

  numberValidationLoading = false;

  audioFiles: Playback[] = [];
  selectedAudioFile: Playback | null = null;
  audioMode: "play" | "pause" | "" = "";

  interval!: number;

  @Validations()
  validations = {
    newParticipantForm: {
      name: {},
      phone: {
        required,
        noLetters: ContactsService.noLettersValidator,
        format: this.phoneValidation,
      },
    },
  };

  mounted(): void {
    this.getGroups().then(() => this.getRegistrationDates());
    this.getPlaybacks();

    this.interval = setInterval(() => {
      if (this.conference) {
        this.getPlaybackStatus();
      }
    }, 5000)
  }

  destroyed(): void {
    if (this.interval) clearInterval(this.interval);
  }

  switchSelection(): void {
    EventBus.$emit(Events.SwitchSelection);
  }

  deselect(): void {
    EventBus.$emit(Events.DeselectAll);
  }

  getGroups(): Promise<any> {
    return GroupsService.getGroups()
      .then((groups) => {
        this.groups = [
          this.groups[0],
          ...groups.map((group) => {
            const count = group.numberOfContact ? +group.numberOfContact : 0;
            const name = `${group.name} (${count})`;
            return { title: group.name, value: group.name, text: name, count };
          }),
        ];
        this.group = "";
        this.bulkGroup = "";
      })
      .catch((err) => console.log(err.message));
  }

  getPlaybacks(): void {
    PlaybackService.getPlaybacks().then((pb) => {
      this.audioFiles = pb;
      if(this.booking.audioId) {
        const audio = pb.find(p => p.id === this.booking.audioId); 
        if(audio) {
          this.selectedAudioFile = audio;
          this.getPlaybackStatus();
        }
      }
    });
  }

  getPlaybackStatus(): void {
    PlaybackService.getPlaybackStatus(this.conference.id)
                  .then(res => {
                    console.log(res)
                    if(this.selectedAudioFile === null) {
                      this.selectedAudioFile = this.audioFiles.find(f => f.id === res['playing-file-id']) || null;
                    }
                    if(res.status === 'PLAYING') this.audioMode = 'play';
                    if(res.status === 'PAUSED') this.audioMode = 'pause';
                    if(res.status === 'STOPPED') this.audioMode = '';
                  })
  }

  getRegistrationDates(): void {
    if (this.booking.regDate) {
      RegistrationService.getRegistration(
        this.booking.regDate.regId.toString()
      ).then((reg) => {
        let regDates = reg.regDates?.filter(date => date.id === this.booking.regDate?.dateId);
        if (regDates) {
          this.setRegGroups(regDates, reg);
        }
      });
    } else {
      RegistrationService.getRegistrations(
        0, 250, 'dateCreated', 'DESC'
      ).then(res => {
        let regDates = [] as RegistrationDate[];
        res.content.forEach(reg => {
          reg.regDates?.forEach(date => {
            if(new Date(date.start).toDateString() === new Date().toDateString()) {
              regDates.push(date)
            }
          })
          if(regDates.length > 0) {
            this.setRegGroups(regDates, reg);
            regDates = [];
          }
        })
      })
    }
  }

  setRegGroups(dates: RegistrationDate[], reg?: Registration): void {
    const adressbookGroups = [...this.groups];
    adressbookGroups.shift()
    const regGroups = [
      ...dates.map((date) => {
        const localeString = new Date(date.start).toLocaleString(
          undefined,
          {
            dateStyle: "short",
            timeStyle: "short",
          }
        );
        let count = 0;
        if (reg && reg.doi && reg.doi.type !== 'DISABLED') {
          count = date.countDoiUser || 0;
        } else {
          count = date.countRegisteredUser || 0;
        }
        const d = `AF: ${localeString} (${count})`;
        return {
          title: d,
          text: d,
          value: date.id || "0",
          count: date.countRegisteredUser ? date.countRegisteredUser : 0,
          reg
        };
      }),
    ];

    this.groups = [
      this.groups[0],
      ...regGroups,
      ...adressbookGroups
    ]
  }

  selectAudioFile(file: Playback): void {
    this.selectedAudioFile = file;
  }

  playFile(): void {
    //
    if (this.conference && this.selectedAudioFile) {
      ConferenceService.startPlayback(
        this.conference.id,
        this.selectedAudioFile.id
      ).then(() => {
        this.audioMode = "play";
      });
    }
  }
  pauseFile(): void {
    //
    if (this.audioMode === "play") {
      ConferenceService.pausePlayback(this.conference.id).then(() => {
        this.audioMode = "pause";
      });
    }
  }
  stopFile(): void {
    //
    if (this.audioMode === "play" || this.audioMode === "pause") {
      ConferenceService.stopPlayback(this.conference.id).then(() => {
        this.audioMode = "";
      });
    }
  }

  resumeFile(): void {
    if (this.audioMode === "pause") {
      ConferenceService.resumePlayback(this.conference.id).then(() => {
        this.audioMode = "play";
      });
    }
  }

  openAddParticipantModal(): void {
    this.$bvModal.show('modal-add-participant');
  }

  addContactAs(role: 'PARTICIPANT' | 'MODERATOR', ok: () => void): void {
    this.$v.newParticipantForm.$touch();
    if (this.numberValidationLoading) {
      setTimeout(() => {
        this.addContactAs(role, ok);
      }, 500);
    } else {
      if (!this.$v.newParticipantForm.$invalid) {
        const participant: DialOutParticipant = {
          phone: this.newParticipantForm.phone,
          label: this.newParticipantForm.name
            ? this.newParticipantForm.name
            : "",
          matchAddressbook: true,
          role
        };
        ok();
        this.$emit("addParticipant", participant);
        this.newParticipantForm = { ...this.resetParticipantForm };
        this.$v.newParticipantForm.$reset();
      }
    }
  }

  addGroupContacts(): void {
    if(this.group)
    this.$bvModal.show('modal-add-group');
  }

  addGroupContactsAs(role: 'PARTICIPANT' | 'MODERATOR', ok: () => void): void {
    const group = this.groups.find((g) => g.value === this.group);
    if (group) {
      if (group.reg) {
        console.log('adding')
        this.$emit("addFormContacts", {group, role});
      } else {
        this.$emit("addGroupContacts", {group, role});
      }
      this.group = "";
    } else {
      console.log('no group found', this.group);
    }
    ok();
  }

  changeStatus(status: ConferenceParticipantAudioType): void {
    this.$emit("changeSelected", status);
  }

  phoneValidation(): boolean | Promise<boolean> {
    const telephone = this.$v.newParticipantForm.phone;
    if (telephone?.$dirty) {
      this.numberValidationLoading = true;
      return ContactsService.validatePhoneNumbers([
        this.newParticipantForm.phone,
      ])
        .then((validatedNumbers) => {
          const validNumber = validatedNumbers[0];
          const phoneIsValid = validNumber.validNumber;
          if (phoneIsValid) {
            this.newParticipantForm.phone = validNumber.international;
          }
          this.numberValidationLoading = false;
          return phoneIsValid;
        })
        .catch((err) => {
          this.toast(err.message, "danger");
          this.numberValidationLoading = false;
          return false;
        });
    }
    return true;
  }

  validateState(name: string): boolean | null {
    const validate: any = this.$v.newParticipantForm[name];
    return validate.$dirty ? !validate.$error : null;
  }
}
