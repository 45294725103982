















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CFooter extends Vue {
  @Prop() icon!: string;
  @Prop() text!: string;
  @Prop() shortText!: string;
  @Prop({default: false, type: Boolean}) pulse!: boolean;
  @Prop({default: false, type: Boolean}) active!: boolean;

  @Prop({ type: Boolean, default: false }) inactive!: string;

  onClick(event: Event): void {
    this.$emit('click');
    if (event.target) {
        (event.target as HTMLButtonElement).blur();
    }
  }
}
