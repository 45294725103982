import { Playback } from "@/models/Playback";
import axios from "axios";

export interface PlaybackStatus {
  status: 'STOPPED' | 'PLAYING' | 'PAUSED';
  'playing-file-id': number; 
}

/**
 * API calls for playbacks
 * @module service/PlaybackService
 */
export default {
  getPlaybacks(): Promise<Playback[]> {
    return axios.get("/audio").then((res) => res.data);
  },
  downloadPlayback(id: number, name: string): Promise<Blob> {
    return axios
      .get(`/audio/${id}/${name}`, { responseType: "blob" })
      .then((res) => res.data);
  },
  uploadPlayback(name: string, blob: Blob): Promise<void> {
    const form = new FormData();
    form.append("file", blob);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return axios.post(`/audio/upload/${name}`, form, { headers }).then();
  },
  deletePlayback(id: number): Promise<void> {
    return axios.delete(`/audio/${id}`).then();
  },
  getPlaybackStatus(confId: string): Promise<PlaybackStatus> {
    return axios
      .get(`/conference/${confId}/playback/status`)
      .then((res) => res.data);
  },
};