import {
  Conference,
  ConferenceParticipant,
  ConferenceParticipantAudioType,
  DialOutParticipant,
  RtsState,
} from "@/models/Conference";
import axios from "axios";

/**
 * API calls for booking
 * @module service/ConferenceService
 */
export default {
  /**
   * Rest Functions bookings
   */
  getConferences(): Promise<Array<Conference>> {
    return axios.get("/conference").then((response) => response.data);
  },

  getConference(id: string): Promise<Conference> {
    return axios.get("/conference/" + id).then((response) => response.data);
  },

  controlConference(id: string, conference: any): Promise<void> {
    return axios.post("/conference/" + id, conference).then();
  },

  getAllParticipants(id: string): Promise<Array<ConferenceParticipant>> {
    return axios
      .get(`/conference/${id}/participants`)
      .then((res) => res.data.conferenceParticipants);
  },

  getSpeakers(id: string): Promise<Array<ConferenceParticipant>> {
    return axios
      .get(`/conference/${id}/speakers`)
      .then((res) => res.data.conferenceSpeakers);
  },

  stopConference(id: string): Promise<void> {
    return axios.delete("/conference/" + id).then();
  },

  updateStatus(
    id: string,
    participantIds: string[],
    state: ConferenceParticipantAudioType
  ): Promise<void> {
    const obj = participantIds.map((id) => ({
      audioState: state,
      participantId: id,
    }));
    return axios.post(`/conference/${id}/participants/status`, obj).then();
  },

  updateLabel(
    id: string,
    participantId: string,
    label: string
  ): Promise<void> {
    const obj = [{
      label,
      participantId,
    }];
    return axios.post(`/conference/${id}/participants/status`, obj).then();
  },

  updateRTS(
    id: string,
    participantIds: string[],
    rtsState: RtsState
  ): Promise<void> {
    const obj = participantIds.map((id) => ({ rtsState, participantId: id }));
    return axios.post(`/conference/${id}/participants/status`, obj).then();
  },

  addParticipants(
    id: string,
    participants: DialOutParticipant[]
  ): Promise<void> {
    return axios
      .post(`/conference/${id}/participants`, { participants })
      .then();
  },

  startPlayback(id: string, fileId: number): Promise<void> {
    return axios.post(`/conference/${id}/playback/start/${fileId}`).then();
  },
  resumePlayback(id: string): Promise<void> {
    return axios.post(`/conference/${id}/playback/resume`).then();
  },
  stopPlayback(id: string): Promise<void> {
    return axios.post(`/conference/${id}/playback/stop`).then();
  },
  pausePlayback(id: string): Promise<void> {
    return axios.post(`/conference/${id}/playback/pause`).then();
  },

  addParticipantsFromForm(
    id: string,
    regId: string,
    dateId: string
  ): Promise<void> {
    return axios
      .post(`/conference/${id}/participantsFromForm/${regId}/${dateId}`)
      .then();
  },

  // APP FUNCTIONS
  isDisconnected(str: ConferenceParticipantAudioType): boolean {
    return (
      str === "DISCONNECTED" ||
      str === "BUSY" ||
      str === "HANGUP" ||
      str === "CONNECTION_LOST" ||
      str === "LATER" ||
      str === "FAILED"
    );
  },
};
