






















import { BFormInput } from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CBodyNameInput extends Vue {
  @Prop() item!: any;

  name = "";
  editMode = false;

  nameChanged(event: string): void {
    this.$emit("nameChanged", event);
    this.item.name = event;
  }

  inputClicked(): void {
    this.name = this.item.name;
    this.editMode = true;
    setTimeout(() => {
      const ref = this.$refs["nameInput-" + this.item.id] as BFormInput;
      if (ref) {
        ref.focus();
      }
    });
  }

  blur(): void {
    this.editMode = false;
    this.name = "";
  }
}
