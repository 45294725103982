export default {
    sessionExpired: "Session expired",
    closeWindowAndLogin: 'Please close this window and log in again.',
    callGroup: 'Call group',
    search: 'Search',
    name: 'Name',
    phoneNumber: 'Phone number',
    phoneNumberShort: 'Phone no.',
    contacts: 'Contacts',
    noGroup: 'No group',
    connectionEstablish: 'Establishing connection',
    conferenceEnded: 'The conference has ended',
    connectionProblems: 'Connection problems',
    reloadPage: 'Reload page',
    connectParticipant: 'Connect participant',
    selectedParticipants: 'Selected participants',
    callAsModerator: 'Call as moderator',
    callAsMod: 'Call as mod.',
    callAllParticipants: 'Call all participants',
    callAsPart: 'Call as participant',
    wantConnectionAsModeratorOrParticipant: 'Do you want to connect as a moderator or participant?',
    moderatorAdded: 'Moderator added',
    participantAdded: 'Participant added',
    participantExists: 'Participant already exists',
    requestToSpeakDeleted: 'All requests to speak have been deleted',
    serverErrorOccurred: 'A server error occurred',
    mute: 'Mute',
    call: 'Call',
    disconnect: 'Disconnect',
    park: 'Park',
    switchLoud: 'Switch loud',
    free: 'Free',
    blocked: 'Blocked',
    dialing: 'Dialing',
    occupied: 'Occupied',
    greeting: 'Greeting',
    speaker: 'Speaker',
    listener: 'Listener',
    parked: 'Parked',
    later: 'Later',
    notReached: 'Not reached',
    unknown: 'Unknown',
    undefined_: 'Undefined',
    nEntriesPerPage: '{number} entries/page',
    tableview: 'Table view',
    mapview: 'Map view',
    selectedParticipantsCap: 'Selected participants',
    selectAction: 'Select action',
    muteIfConnected: 'Mute if connected',
    switchLoudIfConnected: 'Switch loud if connected',
    parkIfConnected: 'Park if connected',
    callIfDisconnected: 'Call if disconnected',
    invertSelection: 'Invert selection',
    deselect: 'Deselect',
    connection2: 'Connection',
    callNumber: 'Call number',
    fieldRequired: 'Field required, must be filled out',
    insertValidNumber: 'Please enter a valid mobile number',
    playAudio: 'Play audio',
    playback: 'Playback',
    selectAudiofile: 'Select audio file',
    audio: 'Audio',
    moderatorShort: 'Mod.',
    participantShort: 'Part.',
    moderatorPin: 'PIN-Moderators',
    participantPin: 'PIN-Participants',
    dialInNumbers: 'Dial-in numbers',
    conference: 'Conference',
    ifConnected: ', if connected',
    ifDisconnected: ', if disconnected',
    delete: 'Delete',
    addressbook: 'Address book',
    finish: 'Finish',
    recording: 'Recording',
    recordingShort: 'Rec.',
    recordingActive: 'Recording active',
    recordingNotActive: 'Recording not activated',
    conferenceShort: 'Conf.',
    roomOpen: 'Conference room open',
    roomLocked: 'Conference room locked',
    waitingRoom: 'Waiting room',
    waitingRoomShort: 'Wait.',
    waitingRoomActive: 'Waiting room activated',
    waitingRoomOff: 'Waiting room off',
    participantSearch: 'Participant search',
    startRecordingModal: {
        title: 'Start recording',
        msg: 'Do you want to start recording? This cannot be turned off again',
    },
    endConferenceModal: {
        title: 'End conference',
        msg: 'Do you want to end the conference?',
    },
    cancel: 'Cancel',
    wantToEndConference: 'Do you want to end the conference?',
    all: 'All',
    speakers: 'Speakers',
    listeners: 'Listeners',
    requestToSpeaks: 'Requests to speak',
    requestsToSpeakShorter: 'Requests',
    speakerShort: 'Spk.',
    listenerShort: 'Lstn.',
    requestToSpeakShort: 'Req.',
    start: 'Start',
    status: 'Status',
    participantInConference: 'Participant already in conference',
    pageOf: 'Page {current} of {total}',
    shownElements: 'Displayed {title}',
    elements: 'Elements',
    elementsOf: '{current} of {total}',
    asModerator: 'As Moderator',
    asParticipant: 'As Participant',
    extras: 'Extras',
    allParticipants: 'All participants',
    allRequestsToSpeak: 'All requests to speak',
    selectGroup: 'Select group',
}
