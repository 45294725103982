











































































































import { EventBus, Events } from '@/EventBus';
import { Booking } from '@/models/api';
import { Conference, ConferenceParticipantAudioType, ConferenceRow, RtsState } from '@/models/Conference';
import ConferenceService from '@/service/ConferenceService';
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import CBodyStatus from '@/components/CBodyStatus.vue';
import CBodyNameInput from '@/components/CBodyNameInput.vue';
import {RecycleScroller} from 'vue-virtual-scroller';
import { BFormInput } from 'bootstrap-vue';
import Base from "@/Base";

type Counts = {
  total: number;
  speaker: number;
  listeners: number;
  parked: number;
  rts: number;
}

@Component({
  components: {CBodyStatus, RecycleScroller, CBodyNameInput}
})
export default class CBody extends Base {
  @Prop() booking!: Booking;
  @Prop() conference!: Conference;
  @Prop() counts!: Counts;

  tabIndex = 0;

  @Prop() rows!: ConferenceRow[];
  filteredRows: ConferenceRow[] = [];
  speakerIds: string[] = [];
  
  sortBy = '';
  sortDesc = false;

  allSelected = false;
  loading = false;
  @PropSync('selectedRows') selected!: ConferenceRow[];

  fields = [
    { key: 'info', label: '', thStyle: {width: '17px'}, class: 'va-middle pl-2 pr-2 text-sm d-none d-sm-block' },
    { key: 'id', label: '', thStyle: {width: '25px'}, class: 'va-middle' },
    { key: 'number', label: ' ', thStyle: {width: '58px'}, class: 'truncate', sortable: true },
    { key: 'name', label: this.t('name'), sortable: true, thStyle: {width: '130px'},class:'flex-grow-1', sortDirection: 'desc', tdClass: 'truncate' },
    { key: 'telephone', label: this.t('phoneNumberShort'), thStyle: {width: '150px'}, class: 'flex-grow-1 d-none d-sm-block', sortable: true, tdClass: 'truncate' },
    { key: 'dialin', label: '', sortable: true, thStyle: {width: '25px'}, class: 'va-middle pl-2 pr-2 text-sm d-none d-sm-block' },
    { key: 'start', label: this.t('start'),thStyle: {width: '65px'}, sortable: true, tdClass: 'truncate', class: 'd-none d-sm-block'},
    { key: 'status', label: this.t('status'),thStyle: {width: '110px'}, tdClass: 'body-status', sortable: true},
    { key: 'rtsIndex', label: this.t('requestsToSpeakShorter'),thStyle: {width: '95px'}, sortable: true, class: 'va-middle', tdClass: 'd-flex justify-content-center'},
  ];

  ccpBodyStyle = {
    marginTop: '40px'
  }

  scrollerStyle = {
    height: '377px'
  }

  searchTerm = '';

  tabMap = [
    '',
    'CONFERENCING',
    'MUTED',
    'PARKED',
    'RTS'
  ]
  
  @Watch('tabIndex')
  filter(newValue: number, oldValue?: number): void {
    this.setFilter(newValue);
    this.deselect();
    const scroller = this.$refs['scroller'] as any;
    scroller.scrollToPosition(0);
    if (oldValue !== 0 && newValue === 0) {
      this.sort(this.sortBy, true);
    }
  }

  setFilter(index: number): void {
    this.filteredRows = [];
    switch(index) {
      case 0:
        // get participants
        this.filterList(this.rows, (row) => !row.inactive? this.filteredRows.push(row): '');
        break;
      case 1: 
        // get speaker
        this.filterList(this.rows, (row) => row.status === 'CONFERENCING' && !row.inactive? this.filteredRows.push(row): '');
        break;
      case 2: 
        // get listener
        this.filterList(this.rows, (row) => row.status === 'MUTED' && !row.inactive? this.filteredRows.push(row): '');
        break;
      case 3: 
        // waiting room
        this.filterList(this.rows, (row) => row.status === 'PARKED' && !row.inactive? this.filteredRows.push(row): '');
        break;
      case 4: 
        //  meldungen
        this.filterList(this.rows, (row: ConferenceRow) => {
          if(!row.inactive && !ConferenceService.isDisconnected(row.status) && row.rts !== 'OFF') {
            this.filteredRows.push(row)
          } 
        });
        break;
    }
    if (this.searchTerm !== '') {
      this.search(this.searchTerm);
    }
  }


  @Watch('rows')
  update(): void {
    this.filter(this.tabIndex);
  }

  mounted(): void {
    this.filter(0);
    setTimeout(() => {
      this.sort('number');
    },1000);
    this.resized();
    window.addEventListener('resize', this.resized)

    // Events
    EventBus.$on(Events.Search, this.search);
    EventBus.$on(Events.SwitchSelection, this.switchSelection);
    EventBus.$on(Events.DeselectAll, this.deselect);

  }
  destroyed(): void {
    window.removeEventListener('resize', this.resized)

    // Events
    EventBus.$off(Events.Search);
    EventBus.$off(Events.SwitchSelection);
    EventBus.$off(Events.DeselectAll);

  }
  
  resized(): void {
    setTimeout(() => {
      // window height minus height of header, header tabset, table header, footer tabset, footer 
      this.scrollerStyle.height = (window.innerHeight - 134 - 40 - 38 - 67 - 43) + 'px'; 
    }, 150);
  }

  search(searchTerm: string): void {
    const isnumber = /^\d+$/.test(searchTerm.replace('+', ''));
    if(isnumber) {
      searchTerm = searchTerm.replace(' ', '')
                            .replace(/[&/\\#, +()$~%.'":*?<>{}]/g, '')
                            .replace('-', '')
                            .replace('_', '');
    }
    this.searchTerm = searchTerm
    if (searchTerm === '') {
      this.filter(this.tabIndex);
    } else {
      let term = searchTerm;
      if (searchTerm.startsWith('00')) term = searchTerm.substring(2);
      else if (searchTerm.startsWith('0')) term = searchTerm.substring(1);
      const r: ConferenceRow[] = []
      this.filterList(this.rows, (row) => {
        const exists = row.name.toLowerCase().includes(term.toLowerCase()) || row.telephone.includes(term);
        if (this.tabIndex === 4) {
          if (exists && row.rtsIndex > 0) {
            r.push(row);
          }
        }
        else if (this.tabIndex > 0 && this.tabIndex < 4) {
          if (exists && (row.status === this.tabMap[this.tabIndex])) {
            r.push(row)
          }
        } else {
          if (exists) {
            r.push(row)
          }
        }
      })
      this.filteredRows = r;
    }
  }

  switchSelection(): void {
    this.rows.forEach(row => row.selected = !row.selected);
    this.selected = this.rows.filter(row => row.selected);
  }

  deselect(): void {
    this.selectAll(false);
    this.allSelected = false;
  }

  setStatus(status: ConferenceParticipantAudioType, participant: ConferenceRow): void {
    this.$emit('statusChanged', {status, participant})
  }

  setRts(accept: RtsState, participant: ConferenceRow): void {
    ConferenceService.updateRTS(this.conference.id, [participant.id], accept)
                    .then()
                    .catch(err => console.log(err.message))
  }

  sort(key: string, noSortDescChange?: boolean): void {
    this.sortDesc = noSortDescChange ? this.sortDesc : (this.sortBy === key ? !this.sortDesc : true);
    this.sortBy = key;

    this.filteredRows = this.filteredRows.sort((aRow, bRow) => {

      const a = (aRow as any)[key]; // or use Lodash `_.get()`
      const b = (bRow as any)[key];
      
      if (aRow.role === 'MODERATOR' && bRow.role !== 'MODERATOR') return -1;
      if (bRow.role === 'MODERATOR' && aRow.role !== 'MODERATOR') return 1;

  
      if (
        (typeof a === 'number' && typeof b === 'number') ||
        (a instanceof Date && b instanceof Date)
      ) {
        // If both compared fields are native numbers or both are native dates
        return this.sortDesc ? (a < b ? -1 : a > b ? 1 : 0) : (a > b ? -1 : a < b ? 1 : 0)
      } else {
        // Otherwise stringify the field data and use String.prototype.localeCompare
        return this.sortDesc ? 
              this.toString(a).localeCompare(this.toString(b), 'de', {}) :
              this.toString(b).localeCompare(this.toString(a), 'de', {})
      }
    })
  }
  
  toString(value: any): string {
    if (value === null || typeof value === 'undefined') {
      return ''
    } else if (value instanceof Object) {
      return Object.keys(value)
        .sort()
        .map(key => this.toString(value[key]))
        .join(' ')
    } else {
      return String(value)
    }
  }

  nameChanged(newLabel: string, item: ConferenceRow): void {
    ConferenceService.updateLabel(this.conference.id, item.id, newLabel)
                  .then();
  }

  // TABLE
  rowClass(item: ConferenceRow): string {

    let cssClass = '';
    if (item && item.selected){
      cssClass += 'c-table-warning'
    }
    return cssClass + ' d-flex';
  }

  
  rowClicked(item: ConferenceRow, index:number, event:any): void {
    this.rowSelected(!item.selected, item, event.shiftKey);
  }

  rowSelected(value: boolean, item: ConferenceRow, shiftKey?: boolean): void {
    if (item.selected !== value)  item.selected = value;
    if (value) {
      if (item.telephone) this.selected.push(item);
    } else {
      const index = this.selected.findIndex(row => item.telephone === row.telephone)
      this.selected.splice(index, 1);
    }
    if (shiftKey && value) {
      this.selectBetween(item, value)
    }
  }

  selectAll(value: boolean): void {
    this.filteredRows.forEach(item => {
      if (item.selected !== value && !item.inactive) {
        this.rowSelected(value, item)
      }
    })
    if (!value) {
      this.selected = [];
    }
  }

  selectBetween(item: ConferenceRow, value: boolean): void {
    const index = this.rows.findIndex(row => item.telephone === row.telephone);
    const selectedIndex = this.rows.findIndex(row => row.selected === value && row.telephone !== item.telephone);
    let startIndex = 0, endIndex = 0;
    if (index < selectedIndex) {
      startIndex = index;
      endIndex = selectedIndex;
    } else {
      startIndex = selectedIndex === -1 ? 0 : selectedIndex;
      endIndex = index;
    }
    this.rows.slice(startIndex, endIndex).forEach(item => {
      if (item.selected !== value && !item.inactive) {
        this.rowSelected(value, item);
      }
    })
  }

  private filterList(list: any[], callback: (item: any) => void) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      callback(item);
    }
  }

}
