import Vue from 'vue'
import Component from 'vue-class-component'

// Base class for all views with common functions
@Component
export default class Base extends Vue {

  t(key: string): string {
    return this.$t(key) as string;
  }

  showNetworkIndicator(): void {
    this.$store.dispatch("setNetworkIndicator", true)
  }

  hideNetworkIndicator(): void {
      this.$store.dispatch("setNetworkIndicator", false)
  }

  showSuccessIndicator(): void {
    this.$store.dispatch("setSuccessIndicator", true)
    setTimeout(() => {
      this.$store.dispatch("setSuccessIndicator", false)
    }, 1000)
  }

  toast(message: string, variant?: string): void {
    this.$root.$bvToast.toast(message, {
      toaster: 'b-toaster-top-center',
      variant: variant ? variant : 'light',
      autoHideDelay: 2000
    });
  }
  
  /**
   *
   * @param error this is the axios error object (https://github.com/axios/axios#handling-errors)
   */
  showNetworkError(error: any): void {
    const text = error.response.data.error ? error.response.data.error : "Unbekannter Fehler";
    this.$root.$bvToast.toast(text, {
      title: "Verbindungsfehler",
      toaster: 'b-toaster-top-center',
      appendToast: true,
      autoHideDelay: 1000,
      variant: "danger"
    });
  }
}