

































































import {Component, Prop, Watch} from "vue-property-decorator";
import WPagination from '@/components/webcall/WPagination.vue';
import WListOptions from '@/components/webcall/WListOptions.vue';
import ContactsService from "@/service/ContactsService";
import { debounce } from "lodash";
import { TableContact } from "@/models/Contact";
import GroupsService from "@/service/GroupsService";
import Base from "@/Base";

@Component({
  components: {WPagination, WListOptions}
})
export default class WAdressbook extends Base {
  @Prop() addedContacts!: string[];

  currentPage = 1;
  perPage = 8;
  searchTerm = '';
  group = '';
  bulkGroup = '';

  loadingContacts = false;
  
  private _totalPages=0;
  get totalPages(): number {
    return this._totalPages;
  }
  set totalPages(value: number) {
    this._totalPages=value;
  }

  groups = [
    {title: 'Keine Gruppe', text: this.t('noGroup'), value: '', count: 0},
  ];
  totalRows = 0;
  totalContacts = 0;
  
  groupIsAdmin = false;
  participantTypes = [
    {title: 'Als Moderator', text: this.t('asModerator'), value: true},
    {title: 'Als Teilnehmer', text: this.t('asParticipant'), value: false},
  ];

  rows: TableContact[] = [];
  selectedRows: TableContact[] = [];

  debounceGetContacts = debounce(this.getContacts, 200);

  @Watch('currentPage')
  currentTablePageChanged(): void {
    if (!this.loadingContacts) {
      setTimeout(() => {
        this.debounceGetContacts();
      });
    }
  }

  @Watch('group')
  currentGroupChanged(): void {
    setTimeout(() => {
      this.debounceGetContacts();
    }, 10);
  }

  @Watch('searchTerm')
  searchChanged(): void {
    setTimeout(() => {
      this.debounceGetContacts();
    }, 300);
  }

  get showPagination(): boolean {
    return this.perPage <= this.totalRows;
  }

  
  mounted(): void {
    this.getGroups();
    this.debounceGetContacts();
  }

  destroyed(): void {
    this.$emit('selected', [])
  }

  selectRow(row: TableContact): void {
    setTimeout(() => {
      console.log(row)
      row.selected = !row.selected;
      if (row.selected) {
        this.selectedRows.push(row);
      } else {
        this.selectedRows = this.selectedRows.filter(r => r.id !== row.id);
      }
      this.$emit('selected', this.selectedRows)
    }, 50);
  }

  getContacts(): void {
    this.loadingContacts = true;
    ContactsService.getContacts(this.currentPage - 1, this.perPage, 'dateCreated', 'ASC', this.searchTerm, this.group)
                  .then(wrapper => {
                    this.currentPage = wrapper.pageNumber + 1;
                    this.totalPages = wrapper.totalPages;
                    this.totalRows = wrapper.totalSize;
                    if (this.totalContacts === 0) this.totalContacts = wrapper.totalSize;
                    if (!wrapper.empty) {
                      this.rows = wrapper.content.map(row => ({
                        ...row, 
                        added: this.addedContacts?.includes(row.telephone.split(' ').join('')) || false,
                        selected: this.selectedRows.map(r => r.id).includes(row.id || ''),
                        admin: false
                      }));
                    } else {
                      this.rows = [];
                    }
                    this.loadingContacts = false;
                  })
                  .catch(this.errorFunction)
  }

  getGroups(): void {
    GroupsService.getGroups()
                .then(groups => {
                  this.groups = [
                    this.groups[0],
                    ...groups.map(group => {
                      const count = group.numberOfContact ? +group.numberOfContact : 0;
                      const name = `${group.name} (${count})`;
                      return ({title: group.  name, value: group.name, text: name, count})
                    })
                  ];
                  this.group = '';
                  this.bulkGroup = '';
                })
                .catch(this.errorFunction)
  }

  addGroup(): void {
    const group = this.groups.find(g => g.value === this.group);
    if (!group) return;
    this.$emit('addGroup', group)
  }

  errorFunction(err: Error): void {
    this.toast(err.message, 'danger');
  }

}
