













































































































import {Booking} from '@/models/api';
import {Conference, ConferenceParticipantAudioType} from '@/models/Conference';
import ConferenceService from '@/service/ConferenceService';
import {Component, Prop} from 'vue-property-decorator';
import CHeaderBtn from './CHeaderBtn.vue';
import {EventBus, Events} from '@/EventBus';
import Base from '@/Base';
import {debounce} from 'lodash';
import BookingService from '@/service/BookingService';

@Component({
  components: {CHeaderBtn}
})
export default class CHeader extends Base {

  @Prop() booking!: Booking;
  @Prop() conference!: Conference;
  @Prop() phones!: string[];

  debounceSearch = debounce(this.search, 300);
  searchTerm = '';
  numbers = '';

  get microphone(): boolean {
    return this.conference.recordingState === 'ON';
  }

  mounted(): void {
    BookingService.getNationalNumbers()
        .then(numbers => this.numbers =
            numbers.filter(n => n.active).map(n => n.displayNumber + (n.description ? ' (' + n.description + ')' : '')).join('<br/>')
        )
  }

  setMicrophone(value: boolean): void {
    if (value) {
      this.$bvModal.msgBoxConfirm(this.t('startRecordingModal.msg'), {
        title: this.t('startRecordingModal.title'),
        okVariant: 'danger',
        okTitle: this.t('startRecordingModal.title'),
        cancelTitle: this.t('cancel'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      }).then(modalValue => {
        if (modalValue) {
          this.conference.recordingState = value ? 'ON' : 'OFF';
          ConferenceService.controlConference(this.conference.id, {recordingState: this.conference.recordingState})
              .then()
              .catch(err => {
                this.conference.recordingState = !value ? 'ON' : 'OFF';
                this.toast(err.message, 'danger')
              });
        }
      })
          .catch(err => {
            this.toast(err.message, 'danger')
          })
    }
  }

  get lock(): boolean {
    return this.conference.lockState === 'OPEN';
  }

  setLock(value: boolean): void {
    this.conference.lockState = value ? 'OPEN' : 'CLOSED';
    ConferenceService.controlConference(this.conference.id, {lockState: this.conference.lockState})
        .then()
        .catch(err => {
          this.conference.lockState = !value ? 'OPEN' : 'CLOSED';
          this.toast(err.message, 'danger')
        });
  }

  get waiting(): boolean {
    return this.conference.auditoriumState === 'PARKED';
  }

  setWaiting(value: boolean): void {
    const idleState = this.booking.joinMuted ? 'MUTED' : 'CONFERENCING';
    this.conference.auditoriumState = value ? 'PARKED' : idleState;
    // TODO Race condition?
    ConferenceService.controlConference(this.conference.id, {auditoriumState: this.conference.auditoriumState})
        .then()
        .catch(err => {
          this.conference.auditoriumState = !value ? 'PARKED' : idleState;
          this.toast(err.message, 'danger')
        });
  }

  hangup(): void {
    this.$bvModal.msgBoxConfirm(this.t('endConferenceModal.msg'), {
      title: this.t('endConferenceModal.title'),
      okVariant: 'danger',
      okTitle: this.t('endConferenceModal.title'),
      cancelTitle: this.t('cancel'),
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: false
    }).then(value => {
      if (value) ConferenceService.stopConference(this.conference.id)
          .then(() => this.$emit('end'));
    })
        .catch(err => {
          this.toast(err.message, 'danger')
        })
  }

  search(): void {
    EventBus.$emit(Events.Search, this.searchTerm);
  }

  changeStatus(status: ConferenceParticipantAudioType): void {
    if (status === 'CONFERENCING' || status === 'MUTED' || status === 'PARKED') {
      ConferenceService.controlConference(this.conference.id, {auditoriumState: status})
          .then()
    } else {
      this.$emit('changeAll', status);
    }
  }

  deleteRts(): void {
    this.$emit('deleteRts');
  }

}
