import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import {AuthenticationStorage} from "@/models/Account";

Vue.use(Vuex)

// Typing the state, see: https://stackoverflow.com/questions/53807294/how-is-the-correct-way-to-work-with-vuex-and-typescript
class State {
  authentication: AuthenticationStorage | null = null;
  receivedAuthenticationFromClient = false;
}

export default new Vuex.Store({
  state: new State(),
  mutations: {
    receivedAuthenticationFromClient (state, received: boolean) {
      state.receivedAuthenticationFromClient = received;
    },
    setAuthentication (state, authentication: AuthenticationStorage) {
      state.authentication = authentication;
      const authenticationJSON = JSON.stringify(state.authentication);
      if (localStorage.getItem("authentication") !== authenticationJSON) {
        localStorage.setItem("authentication", authenticationJSON);
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${authentication.accessToken}`;
    },
    clearAuthentication (state) {
      console.log("clearAuthentication");
      localStorage.removeItem("authentication");
      state.authentication = null;
      delete axios.defaults.headers.common['Authorization'];
    }
  },
  actions: {
    loadAuthentication(context) {
      console.log("loadAuthentication");
      const authenticationJSON = localStorage.getItem("authentication");
      if (authenticationJSON !== null) {
        console.log("Authentication object in localstorage found")
        context.commit('setAuthentication', JSON.parse(authenticationJSON));
      } else {
        console.log("No authentication object in localstorage found")
        context.commit('clearAuthentication');
      }
    }
  },
  getters: {
    accessToken: state => state.authentication ? state.authentication.accessToken : undefined,
    refreshToken: state => state.authentication ? state.authentication.refreshToken : undefined,
    expiration: state => state.authentication ? state.authentication.expiration : undefined,
    username: state => state.authentication ? state.authentication.username : undefined,

    isAuthenticated: state => state.authentication,
    receivedAuthenticationFromClient: state => state.receivedAuthenticationFromClient
  }
})
