

















import Vue from 'vue';
import {Component, Prop, PropSync} from "vue-property-decorator";
import Base from "@/Base";

@Component
export default class WPagination extends Base {
  @Prop() title!: string;
  @Prop() totalRows!: number;
  @Prop() perPage!: number;
  @Prop() pagesSize!: number;
  @Prop({type: Boolean, default: false}) minimal!: boolean;
  @PropSync('currentPage') syncedCurrentPage!: number;

  get totalPages(): number {
    return this.pagesSize || Math.ceil(this.totalRows / this.perPage);
  }
  
  get shownRows(): string {
    const shown = this.syncedCurrentPage * this.perPage;
    const preShown = (shown - this.perPage) + 1;
    if (preShown > this.totalRows) this.prevRows();
    return preShown + ' - ' + (shown > this.totalRows ? this.totalRows : shown);
  }

  nextRows(): void {
    if (this.syncedCurrentPage < this.totalPages) {
      this.syncedCurrentPage++;
      this.$emit('next');
    }
  }

  prevRows(): void {
    if (this.syncedCurrentPage > 1) {
      this.syncedCurrentPage--;
      this.$emit('previous')
    }
  }
}
