/* eslint-disable no-mixed-spaces-and-tabs */
import {
  EmailTemplateWrapper,
  Registration,
  RegistrationDate,
  RegistrationEmail,
  RegistrationField,
  RegistrationsObject,
  RegistrationSpeaker,
  RegistrationStatistic,
  RegistrationUser,
  UsersObject,
} from "@/models/Registration";
import axios from "axios";
import qs from "qs";

/**
 * API calls for registration
 * @module service/RegistrationService
 */
export default {
  // GET
  getRegistrations(
    page: number,
    size: number,
    order: string,
    direction: "ASC" | "DESC",
    search?: string
  ): Promise<RegistrationsObject> {
    const params: { [key: string]: string | number } = {
      page,
      size,
      order,
      direction,
    };
    if (search) params["search"] = search;
    return axios
      .get("/registration", { params })
      .then((response) => response.data);
  },
  getActiveRegistrations(
    page: number,
    size: number,
    order: string,
    direction: "ASC" | "DESC",
    search?: string
  ): Promise<RegistrationsObject> {
    const params: { [key: string]: string | number } = {
      page,
      size,
      order,
      direction,
    };
    if (search) params["search"] = search;
    return axios
      .get("/registration/listActive", { params })
      .then((response) => response.data);
  },
  getPastRegistrations(
    page: number,
    size: number,
    order: string,
    direction: "ASC" | "DESC",
    search?: string
  ): Promise<RegistrationsObject> {
    const params: { [key: string]: string | number } = {
      page,
      size,
      order,
      direction,
    };
    if (search) params["search"] = search;
    return axios
      .get("/registration/listPast", { params })
      .then((response) => response.data);
  },
  getRegistrationStatistics(): Promise<RegistrationStatistic> {
    return axios.get("/registration/statistic").then((res) => res.data);
  },
  getRegistration(id: string): Promise<Registration> {
    return axios.get("/registration/" + id).then((response) => response.data);
  },
  getRegistrationUsers(
    id: number,
    page: number,
    size: number,
    order: string,
    direction: "ASC" | "DESC"
  ): Promise<UsersObject> {
    const params: { [key: string]: string | number } = {
      page,
      size,
      order,
      direction,
    };
    return axios
      .get(`/registration/${id}/user`, { params })
      .then((res) => res.data);
  },
  getRegistrationUsersForDate(
    id: string,
    regId: number,
    page: number,
    size: number,
    order: string,
    direction: "ASC" | "DESC",
    search?: string
  ): Promise<UsersObject> {
    const params: { [key: string]: string | number } = {
      page,
      size,
      order,
      direction,
    };
    if (search && search !== "") params["search"] = search;
    return axios
      .get(`/registration/${regId}/date/${id}/user`, { params })
      .then((res) => res.data);
  },
  getEmailTemplates(formtype: string): Promise<EmailTemplateWrapper> {
    formtype = formtype ? "/" + formtype : "";
    return axios.get(`/mail-text-template${formtype}`).then((res) => res.data);
  },
  getSpeakerImageUrl(regId: number, id: number): string {
    return `${axios.defaults.baseURL}/registration/${regId}/speaker/${id}/photo.jpg`;
  },

  // UPDATE
  updateGeneral(id: number, registration: Registration): Promise<Registration> {
    return axios
      .put(`/registration/${id}`, registration)
      .then((res) => res.data);
  },
  updateDate(
    regId: number,
    id: number,
    date: RegistrationDate
  ): Promise<RegistrationDate> {
    return axios
      .put(`/registration/${regId}/date/${id}`, date)
      .then((res) => res.data);
  },
  updateField(
    regId: number,
    id: number,
    field: RegistrationField
  ): Promise<RegistrationField> {
    return axios
      .put(`/registration/${regId}/field/${id}`, field)
      .then((res) => res.data);
  },
  updateSpeaker(
    regId: number,
    id: number,
    speaker: RegistrationSpeaker
  ): Promise<RegistrationSpeaker> {
    return axios
      .put(`/registration/${regId}/speaker/${id}`, speaker)
      .then((res) => res.data);
  },
  updateUser(
    regId: number,
    id: number,
    user: RegistrationUser
  ): Promise<RegistrationUser> {
    return axios
      .put(`/registration/${regId}/user/${id}`, user)
      .then((res) => res.data);
  },

  // ADD
  addRegistration(registration: Registration): Promise<Registration> {
    return axios
      .post("/registration", registration)
      .then((response) => response.data);
  },
  addDate(regId: number, date: RegistrationDate): Promise<RegistrationDate> {
    return axios
      .post(`/registration/${regId}/date`, date)
      .then((res) => res.data);
  },
  addField(
    regId: number,
    field: RegistrationField
  ): Promise<RegistrationField> {
    return axios
      .post(`/registration/${regId}/field`, field)
      .then((res) => res.data);
  },
  addSpeaker(
    regId: number,
    speaker: RegistrationSpeaker
  ): Promise<RegistrationSpeaker> {
    return axios
      .post(`/registration/${regId}/speaker`, speaker)
      .then((response) => response.data);
  },
  addImage(
    speakerId: number,
    regId: number,
    blob: Blob
  ): Promise<Registration> {
    const form = new FormData();
    form.append("file", blob);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return axios
      .post(`/registration/${regId}/speaker/${speakerId}/image`, form, {
        headers,
      })
      .then((response) => response.data);
  },
  addUser(regId: number, user: RegistrationUser): Promise<RegistrationUser> {
    return axios
      .post(`/registration/${regId}/user`, user)
      .then((response) => response.data);
  },
  sendEmailToDateUsers(
    regId: number,
    id: number,
    mail: RegistrationEmail,
    recipientIds: string[]
  ): Promise<void> {
    return axios
      .post(`/registration/${regId}/date/${id}/user/mail`, {
        ...mail,
        toAll: recipientIds.length === 0,
        recipientIds: recipientIds.map((id) => +id),
      })
      .then();
  },
  sendTestEmailToAdmin(
    regId: number,
    id: number,
    mail: RegistrationEmail,
    recipientIds: string[]
  ): Promise<void> {
    return axios
      .post(`/registration/${regId}/date/${id}/user/testMail`, {
        ...mail,
        toAll: recipientIds.length === 0,
        recipientIds: recipientIds.map((id) => +id),
      })
      .then();
  },
  addFile(blob: Blob): Promise<string> {
    const form = new FormData();
    form.append("file", blob);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return axios
      .post(`/file/uploadTempFile`, form, { headers })
      .then((response) => response.data);
  },

  // DELETE
  deleteRegistration(id: number): Promise<void> {
    return axios.delete("/registration/" + id).then();
  },
  deleteDate(regId: number, id: number): Promise<void> {
    return axios.delete(`/registration/${regId}/date/${id}`).then();
  },
  deleteField(regId: number, id: number): Promise<void> {
    return axios.delete(`/registration/${regId}/field/${id}`).then();
  },
  deleteSpeaker(regId: number, id: number): Promise<void> {
    return axios.delete(`/registration/${regId}/speaker/${id}`).then();
  },
  deleteImage(regId: number, id: number): Promise<void> {
    return axios.delete(`/registration/${regId}/speaker/${id}/image`).then();
  },
  deleteUser(regId: number, id: number): Promise<void> {
    return axios.delete(`/registration/${regId}/user/${id}`).then();
  },
  deleteUsersForDate(regId: number, id: number): Promise<void> {
    return axios.delete(`/registration/${regId}/date/${id}/user`).then();
  },
  deleteSelectedUsersForDate(
    regId: number,
    id: number,
    ids: number[]
  ): Promise<void> {
    return axios
      .delete(`/registration/${regId}/date/${id}/user/selectedUser`, {
        params: { ids: ids },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then();
  },
  deleteAllPastRegisteredUsers(): Promise<void> {
    return axios.delete(`/registration/pastUsers`).then();
  },
  deleteAllPastRegistrationDates(): Promise<void> {
    return axios.delete(`/registration/pastDates`).then();
  },
  deleteAllPastRegistrationForms(): Promise<void> {
    return axios.delete(`/registration/pastForms`).then();
  },

  activateForm(id: number): Promise<void> {
    return axios.put(`/registration/${id}/activate`).then();
  },
  deactivateForm(id: number): Promise<void> {
    return axios.put(`/registration/${id}/deactivate`).then();
  },
};
